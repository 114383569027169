import {OrderDiscount, OrderDiscountSearchEssential, OrderDiscountCalculation} from "@devour/client";

enum GoFrensTagType {
    PERCENT = "percent",
    BOGO = "bogo",
    FLAT_RATE = "flat",
}


export function getPromoInfo(promo: OrderDiscount | OrderDiscountSearchEssential): [GoFrensTagType, string] {
    let tagType: GoFrensTagType;
    let promoTitle: string;

    if (promo.label.includes("BOGO")) {
        [tagType, promoTitle] = [GoFrensTagType.BOGO, "BOGO"];
    } else if (promo.calculation === OrderDiscountCalculation.PERCENTAGE) {
        [tagType, promoTitle] = [GoFrensTagType.PERCENT, `${promo.amount}% OFF`];
    } else if (promo.calculation === OrderDiscountCalculation.FIXEDAMOUNT) {
        [tagType, promoTitle] = [GoFrensTagType.FLAT_RATE, `$${promo.amount} OFF`];
    }

    return [
        tagType,
        promoTitle,
    ];
}
